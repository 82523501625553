import { c as isNil, i as isUndefined } from './typeof-9343f9b0.esm.js';
import 'js-cookie';
import 'validator';
import 'just-omit';
import 'just-pick';
import 'just-kebab-case';
import 'just-capitalize';
import 'just-clamp';
import 'just-clone';
import 'just-compare';
import 'just-debounce-it';
import 'just-extend';
import 'just-filter-object';
import 'just-flush';
import 'just-memoize';
import 'just-once';
import 'just-prune';
import 'just-safe-set';
import 'just-shuffle';
import 'just-split';
import 'just-throttle';
import 'just-truncate';
import 'uuid';

const isEmptyString = s => typeof s === 'string' && s?.trim().length === 0;
const isEmptyObject = o => Object.keys(o).length === 0;
const filterEntries = (predicate, o) => isNil(o) ? {} : Object.fromEntries(Object.entries(o).filter(predicate));

// Removes key/value pairs from an object where the value is null or undefined
const removeNilValues = o => filterEntries(([_k, v]) => !isNil(v), o);

// Stolen from https://github.com/sindresorhus/is-plain-obj
function isPlainObject(value) {
  if (typeof value !== 'object' || value === null) {
    return false;
  }
  const prototype = Object.getPrototypeOf(value);
  return (prototype === null || prototype === Object.prototype || Object.getPrototypeOf(prototype) === null) && !(Symbol.toStringTag in value) && !(Symbol.iterator in value);
}
function toFormData(o) {
  return Object.entries(o).reduce((d, [key, value]) => {
    if (value != null) {
      d.append(key, typeof value !== 'string' ? JSON.stringify(value) : value);
    }
    return d;
  }, new FormData());
}
const removeNilValuesFromObject = o => {
  return Object.fromEntries(Object.entries(o).filter(([_key, value]) => !isNil(value)).map(([key, value]) => [key, String(value)]));
};
function stringify(object, options) {
  const delimiter = options && options.delimiter ? options.delimiter : '&';
  const removeNil = options && !isUndefined(options.removeNil) ? options.removeNil : true;
  return Object.entries(removeNil ? removeNilValues(object) : object).reduce((accumulator, [key, value]) => [...accumulator, `${String(key)}=${String(value)}`], []).join(delimiter);
}

export { isEmptyObject as a, removeNilValuesFromObject as b, isPlainObject as c, filterEntries as f, isEmptyString as i, removeNilValues as r, stringify as s, toFormData as t };
